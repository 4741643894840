.suggestions {
  position: absolute;
  top: 60%;
  left: 0;
  right: 0;
  z-index: 2;
  background: #fff;
  border: 1px solid #ccc;
  border-top: 0;
  padding: 0.5em;
  font-size: 1em;
  font-weight: bold;
  color: #000;
  cursor: pointer;
}
.suggestions__item {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  list-style-type: none;
}

.suggestions__item:hover {
  background: #eee;
}

.suggestions__text {
  padding-left: 4px;
}

.audio_player {
  margin-left: 10px;
}
.audio-container3 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.cover {
  margin-left: 4px;
}
